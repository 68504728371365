import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { RouteComponentProps, navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import NewHomeHeader from './component/new-home-header';
import HomeAchievementSituation from './component/home-achievement-situation';
import HomeAnaOpt from './component/home-ana-opt';
import HomeSalesArea from './component/home-sales-area';
import HomePerformanceOpt from './component/home-performance-opt';
import HomePerformanceSalesRank from './component/home-performance-sales-rank';
import { BlockLoading2 } from '@/components';
import _ from 'lodash';
import HomePerformanceCityGroupRank from './component/home-performance-city-group-rank';
import HomeProcessInfo from './component/home-process-info/index';
import HomeMineTaskInfo from './component/home-mine-task';
import HomeMineCustomerInfo from './component/home-mine-customer/index';
import HomeMineFrontSaleInfo from './component/home-mine-front-sale/index';
import HomeMineBehindSaleInfo from './component/home-mine-behind-sale/index';
import HomeCommentCard from './component/home-comment-card';
import HomeTlWorkTable from './component/home-tl-worktable';
import { defaultAxios, useRefresh } from '@/utils';

const HomeNewPage: React.FC<RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};
    const bannerImg = `${process.env.PUBLIC_URL}/imgs/menus/banner.png`;
    const {
        showHomeNewPage,
        showHomeAuthLoading,
        getHomeNewAuth,
        homeNewSelectedData,
        setHomeNewFilterData,
        homeNewFilterData,
        fetchHomePageRightInfo,
    } = homeNewStore;
    const selectedLevel = homeNewSelectedData.selectedLevel;
    useEffect(() => {
        getHomeNewAuth();
    }, []);

    useEffect(() => {
        if (!showHomeNewPage && !showHomeAuthLoading) {
            navigate('/home');
        }
    }, [showHomeNewPage, showHomeAuthLoading]);
    useEffect(() => {
        if (selectedLevel != 0) {
            // 从0切走，则level变成m+
            setHomeNewFilterData({
                ...homeNewFilterData,
                targetLevel: 2,
            });
        }
    }, [selectedLevel]);
    useEffect(() => {
        if (
            !_.isNil(homeNewSelectedData.selectedLevel) &&
            !_.isNil(homeNewSelectedData.selectedId)
        ) {
            fetchHomePageRightInfo();
        }
    }, [homeNewSelectedData]);
    useRefresh(['customer'], () => {
        fetchHomePageRightInfo();
    });
    if (showHomeAuthLoading || !showHomeNewPage) {
        return <BlockLoading2 />;
    }
    const isSaleAndGroup =
        homeNewSelectedData.selectedLevel === 4 ||
        homeNewSelectedData.selectedLevel === 1;
    return (
        <>
            <div className="home-new-banner-content">
                <div className="banner-space left-space"></div>
                <img src={bannerImg} alt="" />
                {/* <div
                    className="crm-img-banner"
                    style={{
                        backgroundImage: bannerImg,
                    }}
                ></div> */}
                <div className="banner-space right-space"></div>
            </div>
            <div className="home-new-content">
                <div className="home-new-content-left">
                    <NewHomeHeader />
                    <HomeAchievementSituation />
                    {selectedLevel === 1 && <HomeTlWorkTable />}
                    {selectedLevel === 4 && <HomePerformanceSalesRank />}
                    <HomeAnaOpt />
                    {(selectedLevel === 3 || selectedLevel === 0) && (
                        <HomeSalesArea />
                    )}
                    {(selectedLevel === 1 || selectedLevel === 2) && (
                        <HomePerformanceCityGroupRank />
                    )}
                    <HomePerformanceOpt />
                </div>
                <div className="home-new-content-right">
                    {isSaleAndGroup && (
                        <>
                            <HomeMineTaskInfo />
                            <HomeMineCustomerInfo />
                        </>
                    )}
                    <HomeMineFrontSaleInfo />
                    <HomeMineBehindSaleInfo />
                    <HomeProcessInfo />
                    {isSaleAndGroup && <HomeCommentCard />}
                </div>
            </div>
        </>
    );
};

export default observer(HomeNewPage);
